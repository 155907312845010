import React from "react";
import { LegalSection, Page, Seo } from "gatsby-theme-portfolio-minimal";

export default function ServicePage() {
  return (
    <>
      <Seo titxle="서비스 이용약관" useTitleTemplate={true} noIndex={true} />
      <Page>
        <LegalSection sectionId="term-service" heading="서비스 이용약관" />
      </Page>
    </>
  );
}
